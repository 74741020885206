// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "n_qs d_gv d_cs";
export var heroHeaderCenter = "n_gw d_gw d_cs d_dw";
export var heroHeaderRight = "n_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "n_qt d_gr d_cw";
export var heroParagraphCenter = "n_gs d_gs d_cw d_dw";
export var heroParagraphRight = "n_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "n_qv d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "n_qw d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "n_qx d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "n_qy d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "n_qz d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "n_qB q_qB";
export var heroExceptionNormal = "n_qC q_qC";
export var heroExceptionLarge = "n_qD q_qD";
export var Title1Small = "n_qF q_qF q_qN q_qP";
export var Title1Normal = "n_qG q_qG q_qN q_qQ";
export var Title1Large = "n_qH q_qH q_qN q_qR";
export var BodySmall = "n_qJ q_qJ q_qN q_q5";
export var BodyNormal = "n_qK q_qK q_qN q_q6";
export var BodyLarge = "n_qL q_qL q_qN q_q7";