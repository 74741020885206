// extracted by mini-css-extract-plugin
export var lbContainerOuter = "w_tr";
export var lbContainerInner = "w_ts";
export var movingForwards = "w_tt";
export var movingForwardsOther = "w_tv";
export var movingBackwards = "w_tw";
export var movingBackwardsOther = "w_tx";
export var lbImage = "w_ty";
export var lbOtherImage = "w_tz";
export var prevButton = "w_tB";
export var nextButton = "w_tC";
export var closing = "w_tD";
export var appear = "w_tF";